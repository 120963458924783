import * as React from "react";
import { HeaderRow, HeaderText } from "./QualificationRankings.styles";

export const QualificationRankingsHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
    const headerData = ["Rank", "Team", "Wins", "Losses", "Ties"];

    return (
        <React.Fragment>
            <HeaderRow>
                {headerData.map((data) => {
                    return <HeaderText>{data}</HeaderText>;
                })}
            </HeaderRow>
        </React.Fragment>
    );
};
