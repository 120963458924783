import React from "react";
import History from "../components/PageSwitch/pages/History/History.page";
const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <React.Fragment>
            <History />
        </React.Fragment>
    );
};

export default Page;
