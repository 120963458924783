export interface IScore {
    readonly matchNumber: number;
    readonly agent0: string;
    readonly agent1: string;
    readonly winner: string | null;
}

export const qualificationScores: Array<IScore> = [
    {
        matchNumber: 1,
        agent0: "Refiner",
        agent1: "LEVAI ACKERMAN",
        winner: "Refiner",
    },
    {
        matchNumber: 2,
        agent0: "LEVAI ACKERMAN",
        agent1: "Refiner",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 3,
        agent0: "Wigglyblob",
        agent1: "LEVAI ACKERMAN",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 4,
        agent0: "Wigglyblob",
        agent1: "LEVAI ACKERMAN",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 5,
        agent0: "LEVAI ACKERMAN",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 6,
        agent0: "LEVAI ACKERMAN",
        agent1: "The Screw Shop",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 7,
        agent0: "rng for the win :>",
        agent1: "LEVAI ACKERMAN",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 8,
        agent0: "LEVAI ACKERMAN",
        agent1: "rng for the win :>",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 9,
        agent0: "LEVAI ACKERMAN",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 10,
        agent0: "bruh",
        agent1: "LEVAI ACKERMAN",
        winner: "bruh",
    },
    {
        matchNumber: 11,
        agent0: "LEVAI ACKERMAN",
        agent1: "Totoro",
        winner: "Totoro",
    },
    {
        matchNumber: 12,
        agent0: "Totoro",
        agent1: "LEVAI ACKERMAN",
        winner: "Totoro",
    },
    {
        matchNumber: 13,
        agent0: "LEVAI ACKERMAN",
        agent1: "🤔",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 14,
        agent0: "🤔",
        agent1: "LEVAI ACKERMAN",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 15,
        agent0: "LEVAI ACKERMAN",
        agent1: "Sean^2",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 16,
        agent0: "Sean^2",
        agent1: "LEVAI ACKERMAN",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 17,
        agent0: "LEVAI ACKERMAN",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 18,
        agent0: "LEVAI ACKERMAN",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 19,
        agent0: "Die Jigglybluff",
        agent1: "LEVAI ACKERMAN",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 20,
        agent0: "Die Jigglybluff",
        agent1: "LEVAI ACKERMAN",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 21,
        agent0: "LEVAI ACKERMAN",
        agent1: "Mental Moles",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 22,
        agent0: "LEVAI ACKERMAN",
        agent1: "Mental Moles",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 23,
        agent0: "LEVAI ACKERMAN",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 24,
        agent0: "Ascend",
        agent1: "LEVAI ACKERMAN",
        winner: null,
    },
    {
        matchNumber: 25,
        agent0: "blasterpoard",
        agent1: "LEVAI ACKERMAN",
        winner: "blasterpoard",
    },
    {
        matchNumber: 26,
        agent0: "blasterpoard",
        agent1: "LEVAI ACKERMAN",
        winner: "blasterpoard",
    },
    {
        matchNumber: 27,
        agent0: "ValGrowth",
        agent1: "LEVAI ACKERMAN",
        winner: "ValGrowth",
    },
    {
        matchNumber: 28,
        agent0: "ValGrowth",
        agent1: "LEVAI ACKERMAN",
        winner: "ValGrowth",
    },
    {
        matchNumber: 29,
        agent0: "LEVAI ACKERMAN",
        agent1: "JABE",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 30,
        agent0: "LEVAI ACKERMAN",
        agent1: "JABE",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 31,
        agent0: "AvadaKedavra",
        agent1: "LEVAI ACKERMAN",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 32,
        agent0: "AvadaKedavra",
        agent1: "LEVAI ACKERMAN",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 33,
        agent0: "import numpy as pd",
        agent1: "LEVAI ACKERMAN",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 34,
        agent0: "LEVAI ACKERMAN",
        agent1: "import numpy as pd",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 35,
        agent0: "One Bot: 4 Ever Given",
        agent1: "LEVAI ACKERMAN",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 36,
        agent0: "One Bot: 4 Ever Given",
        agent1: "LEVAI ACKERMAN",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 37,
        agent0: "LEVAI ACKERMAN",
        agent1: "Don't Hug Me I'm Scared",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 38,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "LEVAI ACKERMAN",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 39,
        agent0: "Team Drake",
        agent1: "LEVAI ACKERMAN",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 40,
        agent0: "Team Drake",
        agent1: "LEVAI ACKERMAN",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 41,
        agent0: "PDNN (= please do not nerf)",
        agent1: "LEVAI ACKERMAN",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 42,
        agent0: "LEVAI ACKERMAN",
        agent1: "PDNN (= please do not nerf)",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 43,
        agent0: "LEVAI ACKERMAN",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 44,
        agent0: "LEVAI ACKERMAN",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 45,
        agent0: "LEVAI ACKERMAN",
        agent1: "pyrados",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 46,
        agent0: "LEVAI ACKERMAN",
        agent1: "pyrados",
        winner: "LEVAI ACKERMAN",
    },
    {
        matchNumber: 47,
        agent0: "Refiner",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 48,
        agent0: "Wigglyblob",
        agent1: "Refiner",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 49,
        agent0: "Refiner",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 50,
        agent0: "The Screw Shop",
        agent1: "Refiner",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 51,
        agent0: "rng for the win :>",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 52,
        agent0: "rng for the win :>",
        agent1: "Refiner",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 53,
        agent0: "bruh",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 54,
        agent0: "Refiner",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 55,
        agent0: "Refiner",
        agent1: "Totoro",
        winner: "Totoro",
    },
    {
        matchNumber: 56,
        agent0: "Totoro",
        agent1: "Refiner",
        winner: "Totoro",
    },
    {
        matchNumber: 57,
        agent0: "Refiner",
        agent1: "🤔",
        winner: "Refiner",
    },
    {
        matchNumber: 58,
        agent0: "Refiner",
        agent1: "🤔",
        winner: "Refiner",
    },
    {
        matchNumber: 59,
        agent0: "Sean^2",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 60,
        agent0: "Refiner",
        agent1: "Sean^2",
        winner: "Sean^2",
    },
    {
        matchNumber: 61,
        agent0: "Silver Snakes",
        agent1: "Refiner",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 62,
        agent0: "Silver Snakes",
        agent1: "Refiner",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 63,
        agent0: "Refiner",
        agent1: "Die Jigglybluff",
        winner: "Refiner",
    },
    {
        matchNumber: 64,
        agent0: "Die Jigglybluff",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 65,
        agent0: "Mental Moles",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 66,
        agent0: "Mental Moles",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 67,
        agent0: "Ascend",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 68,
        agent0: "Ascend",
        agent1: "Refiner",
        winner: null,
    },
    {
        matchNumber: 69,
        agent0: "blasterpoard",
        agent1: "Refiner",
        winner: "blasterpoard",
    },
    {
        matchNumber: 70,
        agent0: "blasterpoard",
        agent1: "Refiner",
        winner: null,
    },
    {
        matchNumber: 71,
        agent0: "ValGrowth",
        agent1: "Refiner",
        winner: "ValGrowth",
    },
    {
        matchNumber: 72,
        agent0: "ValGrowth",
        agent1: "Refiner",
        winner: "ValGrowth",
    },
    {
        matchNumber: 73,
        agent0: "JABE",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 74,
        agent0: "Refiner",
        agent1: "JABE",
        winner: "Refiner",
    },
    {
        matchNumber: 75,
        agent0: "AvadaKedavra",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 76,
        agent0: "AvadaKedavra",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 77,
        agent0: "import numpy as pd",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 78,
        agent0: "Refiner",
        agent1: "import numpy as pd",
        winner: "Refiner",
    },
    {
        matchNumber: 79,
        agent0: "Refiner",
        agent1: "One Bot: 4 Ever Given",
        winner: "Refiner",
    },
    {
        matchNumber: 80,
        agent0: "One Bot: 4 Ever Given",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 81,
        agent0: "Refiner",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Refiner",
    },
    {
        matchNumber: 82,
        agent0: "Refiner",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Refiner",
    },
    {
        matchNumber: 83,
        agent0: "Team Drake",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 84,
        agent0: "Refiner",
        agent1: "Team Drake",
        winner: "Refiner",
    },
    {
        matchNumber: 85,
        agent0: "PDNN (= please do not nerf)",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 86,
        agent0: "Refiner",
        agent1: "PDNN (= please do not nerf)",
        winner: "Refiner",
    },
    {
        matchNumber: 87,
        agent0: ":3",
        agent1: "Refiner",
        winner: ":3",
    },
    {
        matchNumber: 88,
        agent0: ":3",
        agent1: "Refiner",
        winner: ":3",
    },
    {
        matchNumber: 89,
        agent0: "Refiner",
        agent1: "pyrados",
        winner: "Refiner",
    },
    {
        matchNumber: 90,
        agent0: "pyrados",
        agent1: "Refiner",
        winner: "Refiner",
    },
    {
        matchNumber: 91,
        agent0: "Wigglyblob",
        agent1: "The Screw Shop",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 92,
        agent0: "Wigglyblob",
        agent1: "The Screw Shop",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 93,
        agent0: "Wigglyblob",
        agent1: "rng for the win :>",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 94,
        agent0: "rng for the win :>",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 95,
        agent0: "bruh",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 96,
        agent0: "Wigglyblob",
        agent1: "bruh",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 97,
        agent0: "Wigglyblob",
        agent1: "Totoro",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 98,
        agent0: "Totoro",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 99,
        agent0: "🤔",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 100,
        agent0: "Wigglyblob",
        agent1: "🤔",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 101,
        agent0: "Sean^2",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 102,
        agent0: "Wigglyblob",
        agent1: "Sean^2",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 103,
        agent0: "Wigglyblob",
        agent1: "Silver Snakes",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 104,
        agent0: "Wigglyblob",
        agent1: "Silver Snakes",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 105,
        agent0: "Die Jigglybluff",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 106,
        agent0: "Die Jigglybluff",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 107,
        agent0: "Wigglyblob",
        agent1: "Mental Moles",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 108,
        agent0: "Wigglyblob",
        agent1: "Mental Moles",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 109,
        agent0: "Wigglyblob",
        agent1: "Ascend",
        winner: null,
    },
    {
        matchNumber: 110,
        agent0: "Ascend",
        agent1: "Wigglyblob",
        winner: null,
    },
    {
        matchNumber: 111,
        agent0: "Wigglyblob",
        agent1: "blasterpoard",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 112,
        agent0: "Wigglyblob",
        agent1: "blasterpoard",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 113,
        agent0: "Wigglyblob",
        agent1: "ValGrowth",
        winner: null,
    },
    {
        matchNumber: 114,
        agent0: "Wigglyblob",
        agent1: "ValGrowth",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 115,
        agent0: "JABE",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 116,
        agent0: "Wigglyblob",
        agent1: "JABE",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 117,
        agent0: "AvadaKedavra",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 118,
        agent0: "AvadaKedavra",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 119,
        agent0: "Wigglyblob",
        agent1: "import numpy as pd",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 120,
        agent0: "import numpy as pd",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 121,
        agent0: "Wigglyblob",
        agent1: "One Bot: 4 Ever Given",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 122,
        agent0: "Wigglyblob",
        agent1: "One Bot: 4 Ever Given",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 123,
        agent0: "Wigglyblob",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 124,
        agent0: "Wigglyblob",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 125,
        agent0: "Wigglyblob",
        agent1: "Team Drake",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 126,
        agent0: "Wigglyblob",
        agent1: "Team Drake",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 127,
        agent0: "Wigglyblob",
        agent1: "PDNN (= please do not nerf)",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 128,
        agent0: "Wigglyblob",
        agent1: "PDNN (= please do not nerf)",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 129,
        agent0: "Wigglyblob",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 130,
        agent0: ":3",
        agent1: "Wigglyblob",
        winner: ":3",
    },
    {
        matchNumber: 131,
        agent0: "pyrados",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 132,
        agent0: "pyrados",
        agent1: "Wigglyblob",
        winner: "Wigglyblob",
    },
    {
        matchNumber: 133,
        agent0: "The Screw Shop",
        agent1: "rng for the win :>",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 134,
        agent0: "rng for the win :>",
        agent1: "The Screw Shop",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 135,
        agent0: "bruh",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 136,
        agent0: "The Screw Shop",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 137,
        agent0: "Totoro",
        agent1: "The Screw Shop",
        winner: "Totoro",
    },
    {
        matchNumber: 138,
        agent0: "The Screw Shop",
        agent1: "Totoro",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 139,
        agent0: "🤔",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 140,
        agent0: "The Screw Shop",
        agent1: "🤔",
        winner: "🤔",
    },
    {
        matchNumber: 141,
        agent0: "Sean^2",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 142,
        agent0: "Sean^2",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 143,
        agent0: "Silver Snakes",
        agent1: "The Screw Shop",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 144,
        agent0: "The Screw Shop",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 145,
        agent0: "The Screw Shop",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 146,
        agent0: "Die Jigglybluff",
        agent1: "The Screw Shop",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 147,
        agent0: "The Screw Shop",
        agent1: "Mental Moles",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 148,
        agent0: "The Screw Shop",
        agent1: "Mental Moles",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 149,
        agent0: "The Screw Shop",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 150,
        agent0: "Ascend",
        agent1: "The Screw Shop",
        winner: "Ascend",
    },
    {
        matchNumber: 151,
        agent0: "The Screw Shop",
        agent1: "blasterpoard",
        winner: "blasterpoard",
    },
    {
        matchNumber: 152,
        agent0: "The Screw Shop",
        agent1: "blasterpoard",
        winner: "blasterpoard",
    },
    {
        matchNumber: 153,
        agent0: "ValGrowth",
        agent1: "The Screw Shop",
        winner: "ValGrowth",
    },
    {
        matchNumber: 154,
        agent0: "ValGrowth",
        agent1: "The Screw Shop",
        winner: "ValGrowth",
    },
    {
        matchNumber: 155,
        agent0: "JABE",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 156,
        agent0: "JABE",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 157,
        agent0: "AvadaKedavra",
        agent1: "The Screw Shop",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 158,
        agent0: "The Screw Shop",
        agent1: "AvadaKedavra",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 159,
        agent0: "import numpy as pd",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 160,
        agent0: "The Screw Shop",
        agent1: "import numpy as pd",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 161,
        agent0: "One Bot: 4 Ever Given",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 162,
        agent0: "One Bot: 4 Ever Given",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 163,
        agent0: "The Screw Shop",
        agent1: "Don't Hug Me I'm Scared",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 164,
        agent0: "The Screw Shop",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 165,
        agent0: "Team Drake",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 166,
        agent0: "Team Drake",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 167,
        agent0: "The Screw Shop",
        agent1: "PDNN (= please do not nerf)",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 168,
        agent0: "PDNN (= please do not nerf)",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 169,
        agent0: "The Screw Shop",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 170,
        agent0: ":3",
        agent1: "The Screw Shop",
        winner: ":3",
    },
    {
        matchNumber: 171,
        agent0: "pyrados",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 172,
        agent0: "pyrados",
        agent1: "The Screw Shop",
        winner: "The Screw Shop",
    },
    {
        matchNumber: 173,
        agent0: "bruh",
        agent1: "rng for the win :>",
        winner: "bruh",
    },
    {
        matchNumber: 174,
        agent0: "rng for the win :>",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 175,
        agent0: "Totoro",
        agent1: "rng for the win :>",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 176,
        agent0: "Totoro",
        agent1: "rng for the win :>",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 177,
        agent0: "🤔",
        agent1: "rng for the win :>",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 178,
        agent0: "rng for the win :>",
        agent1: "🤔",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 179,
        agent0: "rng for the win :>",
        agent1: "Sean^2",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 180,
        agent0: "Sean^2",
        agent1: "rng for the win :>",
        winner: "Sean^2",
    },
    {
        matchNumber: 181,
        agent0: "Silver Snakes",
        agent1: "rng for the win :>",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 182,
        agent0: "rng for the win :>",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 183,
        agent0: "rng for the win :>",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 184,
        agent0: "rng for the win :>",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 185,
        agent0: "rng for the win :>",
        agent1: "Mental Moles",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 186,
        agent0: "rng for the win :>",
        agent1: "Mental Moles",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 187,
        agent0: "rng for the win :>",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 188,
        agent0: "Ascend",
        agent1: "rng for the win :>",
        winner: "Ascend",
    },
    {
        matchNumber: 189,
        agent0: "rng for the win :>",
        agent1: "blasterpoard",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 190,
        agent0: "blasterpoard",
        agent1: "rng for the win :>",
        winner: "blasterpoard",
    },
    {
        matchNumber: 191,
        agent0: "ValGrowth",
        agent1: "rng for the win :>",
        winner: "ValGrowth",
    },
    {
        matchNumber: 192,
        agent0: "ValGrowth",
        agent1: "rng for the win :>",
        winner: "ValGrowth",
    },
    {
        matchNumber: 193,
        agent0: "rng for the win :>",
        agent1: "JABE",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 194,
        agent0: "rng for the win :>",
        agent1: "JABE",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 195,
        agent0: "rng for the win :>",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 196,
        agent0: "rng for the win :>",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 197,
        agent0: "import numpy as pd",
        agent1: "rng for the win :>",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 198,
        agent0: "import numpy as pd",
        agent1: "rng for the win :>",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 199,
        agent0: "One Bot: 4 Ever Given",
        agent1: "rng for the win :>",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 200,
        agent0: "One Bot: 4 Ever Given",
        agent1: "rng for the win :>",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 201,
        agent0: "rng for the win :>",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 202,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "rng for the win :>",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 203,
        agent0: "rng for the win :>",
        agent1: "Team Drake",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 204,
        agent0: "rng for the win :>",
        agent1: "Team Drake",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 205,
        agent0: "rng for the win :>",
        agent1: "PDNN (= please do not nerf)",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 206,
        agent0: "rng for the win :>",
        agent1: "PDNN (= please do not nerf)",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 207,
        agent0: "rng for the win :>",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 208,
        agent0: "rng for the win :>",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 209,
        agent0: "rng for the win :>",
        agent1: "pyrados",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 210,
        agent0: "pyrados",
        agent1: "rng for the win :>",
        winner: "rng for the win :>",
    },
    {
        matchNumber: 211,
        agent0: "bruh",
        agent1: "Totoro",
        winner: "bruh",
    },
    {
        matchNumber: 212,
        agent0: "Totoro",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 213,
        agent0: "bruh",
        agent1: "🤔",
        winner: "bruh",
    },
    {
        matchNumber: 214,
        agent0: "bruh",
        agent1: "🤔",
        winner: "bruh",
    },
    {
        matchNumber: 215,
        agent0: "bruh",
        agent1: "Sean^2",
        winner: "bruh",
    },
    {
        matchNumber: 216,
        agent0: "Sean^2",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 217,
        agent0: "Silver Snakes",
        agent1: "bruh",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 218,
        agent0: "Silver Snakes",
        agent1: "bruh",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 219,
        agent0: "bruh",
        agent1: "Die Jigglybluff",
        winner: "bruh",
    },
    {
        matchNumber: 220,
        agent0: "bruh",
        agent1: "Die Jigglybluff",
        winner: null,
    },
    {
        matchNumber: 221,
        agent0: "Mental Moles",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 222,
        agent0: "bruh",
        agent1: "Mental Moles",
        winner: "bruh",
    },
    {
        matchNumber: 223,
        agent0: "Ascend",
        agent1: "bruh",
        winner: null,
    },
    {
        matchNumber: 224,
        agent0: "bruh",
        agent1: "Ascend",
        winner: null,
    },
    {
        matchNumber: 225,
        agent0: "bruh",
        agent1: "blasterpoard",
        winner: "bruh",
    },
    {
        matchNumber: 226,
        agent0: "bruh",
        agent1: "blasterpoard",
        winner: "bruh",
    },
    {
        matchNumber: 227,
        agent0: "bruh",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 228,
        agent0: "bruh",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 229,
        agent0: "bruh",
        agent1: "JABE",
        winner: "bruh",
    },
    {
        matchNumber: 230,
        agent0: "bruh",
        agent1: "JABE",
        winner: "bruh",
    },
    {
        matchNumber: 231,
        agent0: "bruh",
        agent1: "AvadaKedavra",
        winner: "bruh",
    },
    {
        matchNumber: 232,
        agent0: "AvadaKedavra",
        agent1: "bruh",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 233,
        agent0: "bruh",
        agent1: "import numpy as pd",
        winner: "bruh",
    },
    {
        matchNumber: 234,
        agent0: "import numpy as pd",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 235,
        agent0: "One Bot: 4 Ever Given",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 236,
        agent0: "bruh",
        agent1: "One Bot: 4 Ever Given",
        winner: "bruh",
    },
    {
        matchNumber: 237,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 238,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 239,
        agent0: "Team Drake",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 240,
        agent0: "bruh",
        agent1: "Team Drake",
        winner: "bruh",
    },
    {
        matchNumber: 241,
        agent0: "bruh",
        agent1: "PDNN (= please do not nerf)",
        winner: "bruh",
    },
    {
        matchNumber: 242,
        agent0: "PDNN (= please do not nerf)",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 243,
        agent0: ":3",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 244,
        agent0: ":3",
        agent1: "bruh",
        winner: ":3",
    },
    {
        matchNumber: 245,
        agent0: "bruh",
        agent1: "pyrados",
        winner: "bruh",
    },
    {
        matchNumber: 246,
        agent0: "pyrados",
        agent1: "bruh",
        winner: "bruh",
    },
    {
        matchNumber: 247,
        agent0: "Totoro",
        agent1: "🤔",
        winner: "🤔",
    },
    {
        matchNumber: 248,
        agent0: "🤔",
        agent1: "Totoro",
        winner: "Totoro",
    },
    {
        matchNumber: 249,
        agent0: "Sean^2",
        agent1: "Totoro",
        winner: "Totoro",
    },
    {
        matchNumber: 250,
        agent0: "Totoro",
        agent1: "Sean^2",
        winner: "Sean^2",
    },
    {
        matchNumber: 251,
        agent0: "Silver Snakes",
        agent1: "Totoro",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 252,
        agent0: "Silver Snakes",
        agent1: "Totoro",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 253,
        agent0: "Die Jigglybluff",
        agent1: "Totoro",
        winner: "Totoro",
    },
    {
        matchNumber: 254,
        agent0: "Totoro",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 255,
        agent0: "Mental Moles",
        agent1: "Totoro",
        winner: "Totoro",
    },
    {
        matchNumber: 256,
        agent0: "Mental Moles",
        agent1: "Totoro",
        winner: "Totoro",
    },
    {
        matchNumber: 257,
        agent0: "Ascend",
        agent1: "Totoro",
        winner: "Ascend",
    },
    {
        matchNumber: 258,
        agent0: "Totoro",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 259,
        agent0: "Totoro",
        agent1: "blasterpoard",
        winner: null,
    },
    {
        matchNumber: 260,
        agent0: "blasterpoard",
        agent1: "Totoro",
        winner: "blasterpoard",
    },
    {
        matchNumber: 261,
        agent0: "Totoro",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 262,
        agent0: "Totoro",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 263,
        agent0: "JABE",
        agent1: "Totoro",
        winner: "Totoro",
    },
    {
        matchNumber: 264,
        agent0: "Totoro",
        agent1: "JABE",
        winner: "JABE",
    },
    {
        matchNumber: 265,
        agent0: "Totoro",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 266,
        agent0: "AvadaKedavra",
        agent1: "Totoro",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 267,
        agent0: "import numpy as pd",
        agent1: "Totoro",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 268,
        agent0: "import numpy as pd",
        agent1: "Totoro",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 269,
        agent0: "One Bot: 4 Ever Given",
        agent1: "Totoro",
        winner: "Totoro",
    },
    {
        matchNumber: 270,
        agent0: "Totoro",
        agent1: "One Bot: 4 Ever Given",
        winner: "Totoro",
    },
    {
        matchNumber: 271,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "Totoro",
        winner: "Totoro",
    },
    {
        matchNumber: 272,
        agent0: "Totoro",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Totoro",
    },
    {
        matchNumber: 273,
        agent0: "Totoro",
        agent1: "Team Drake",
        winner: "Totoro",
    },
    {
        matchNumber: 274,
        agent0: "Totoro",
        agent1: "Team Drake",
        winner: "Totoro",
    },
    {
        matchNumber: 275,
        agent0: "Totoro",
        agent1: "PDNN (= please do not nerf)",
        winner: "Totoro",
    },
    {
        matchNumber: 276,
        agent0: "Totoro",
        agent1: "PDNN (= please do not nerf)",
        winner: "Totoro",
    },
    {
        matchNumber: 277,
        agent0: "Totoro",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 278,
        agent0: ":3",
        agent1: "Totoro",
        winner: "Totoro",
    },
    {
        matchNumber: 279,
        agent0: "Totoro",
        agent1: "pyrados",
        winner: "Totoro",
    },
    {
        matchNumber: 280,
        agent0: "Totoro",
        agent1: "pyrados",
        winner: "Totoro",
    },
    {
        matchNumber: 281,
        agent0: "🤔",
        agent1: "Sean^2",
        winner: "🤔",
    },
    {
        matchNumber: 282,
        agent0: "Sean^2",
        agent1: "🤔",
        winner: "🤔",
    },
    {
        matchNumber: 283,
        agent0: "Silver Snakes",
        agent1: "🤔",
        winner: "🤔",
    },
    {
        matchNumber: 284,
        agent0: "🤔",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 285,
        agent0: "🤔",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 286,
        agent0: "Die Jigglybluff",
        agent1: "🤔",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 287,
        agent0: "🤔",
        agent1: "Mental Moles",
        winner: "🤔",
    },
    {
        matchNumber: 288,
        agent0: "🤔",
        agent1: "Mental Moles",
        winner: "🤔",
    },
    {
        matchNumber: 289,
        agent0: "🤔",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 290,
        agent0: "🤔",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 291,
        agent0: "🤔",
        agent1: "blasterpoard",
        winner: "🤔",
    },
    {
        matchNumber: 292,
        agent0: "🤔",
        agent1: "blasterpoard",
        winner: "🤔",
    },
    {
        matchNumber: 293,
        agent0: "ValGrowth",
        agent1: "🤔",
        winner: "ValGrowth",
    },
    {
        matchNumber: 294,
        agent0: "ValGrowth",
        agent1: "🤔",
        winner: "ValGrowth",
    },
    {
        matchNumber: 295,
        agent0: "JABE",
        agent1: "🤔",
        winner: "🤔",
    },
    {
        matchNumber: 296,
        agent0: "🤔",
        agent1: "JABE",
        winner: "JABE",
    },
    {
        matchNumber: 297,
        agent0: "AvadaKedavra",
        agent1: "🤔",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 298,
        agent0: "AvadaKedavra",
        agent1: "🤔",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 299,
        agent0: "import numpy as pd",
        agent1: "🤔",
        winner: "🤔",
    },
    {
        matchNumber: 300,
        agent0: "🤔",
        agent1: "import numpy as pd",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 301,
        agent0: "One Bot: 4 Ever Given",
        agent1: "🤔",
        winner: "🤔",
    },
    {
        matchNumber: 302,
        agent0: "One Bot: 4 Ever Given",
        agent1: "🤔",
        winner: "🤔",
    },
    {
        matchNumber: 303,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "🤔",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 304,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "🤔",
        winner: "🤔",
    },
    {
        matchNumber: 305,
        agent0: "🤔",
        agent1: "Team Drake",
        winner: "🤔",
    },
    {
        matchNumber: 306,
        agent0: "🤔",
        agent1: "Team Drake",
        winner: "🤔",
    },
    {
        matchNumber: 307,
        agent0: "🤔",
        agent1: "PDNN (= please do not nerf)",
        winner: "🤔",
    },
    {
        matchNumber: 308,
        agent0: "🤔",
        agent1: "PDNN (= please do not nerf)",
        winner: "🤔",
    },
    {
        matchNumber: 309,
        agent0: ":3",
        agent1: "🤔",
        winner: ":3",
    },
    {
        matchNumber: 310,
        agent0: ":3",
        agent1: "🤔",
        winner: ":3",
    },
    {
        matchNumber: 311,
        agent0: "🤔",
        agent1: "pyrados",
        winner: "🤔",
    },
    {
        matchNumber: 312,
        agent0: "🤔",
        agent1: "pyrados",
        winner: "🤔",
    },
    {
        matchNumber: 313,
        agent0: "Silver Snakes",
        agent1: "Sean^2",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 314,
        agent0: "Silver Snakes",
        agent1: "Sean^2",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 315,
        agent0: "Die Jigglybluff",
        agent1: "Sean^2",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 316,
        agent0: "Die Jigglybluff",
        agent1: "Sean^2",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 317,
        agent0: "Mental Moles",
        agent1: "Sean^2",
        winner: "Mental Moles",
    },
    {
        matchNumber: 318,
        agent0: "Mental Moles",
        agent1: "Sean^2",
        winner: "Sean^2",
    },
    {
        matchNumber: 319,
        agent0: "Ascend",
        agent1: "Sean^2",
        winner: "Ascend",
    },
    {
        matchNumber: 320,
        agent0: "Ascend",
        agent1: "Sean^2",
        winner: "Ascend",
    },
    {
        matchNumber: 321,
        agent0: "blasterpoard",
        agent1: "Sean^2",
        winner: "blasterpoard",
    },
    {
        matchNumber: 322,
        agent0: "blasterpoard",
        agent1: "Sean^2",
        winner: "Sean^2",
    },
    {
        matchNumber: 323,
        agent0: "Sean^2",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 324,
        agent0: "ValGrowth",
        agent1: "Sean^2",
        winner: "ValGrowth",
    },
    {
        matchNumber: 325,
        agent0: "Sean^2",
        agent1: "JABE",
        winner: "JABE",
    },
    {
        matchNumber: 326,
        agent0: "Sean^2",
        agent1: "JABE",
        winner: "Sean^2",
    },
    {
        matchNumber: 327,
        agent0: "AvadaKedavra",
        agent1: "Sean^2",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 328,
        agent0: "AvadaKedavra",
        agent1: "Sean^2",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 329,
        agent0: "import numpy as pd",
        agent1: "Sean^2",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 330,
        agent0: "import numpy as pd",
        agent1: "Sean^2",
        winner: "Sean^2",
    },
    {
        matchNumber: 331,
        agent0: "One Bot: 4 Ever Given",
        agent1: "Sean^2",
        winner: "Sean^2",
    },
    {
        matchNumber: 332,
        agent0: "Sean^2",
        agent1: "One Bot: 4 Ever Given",
        winner: "One Bot: 4 Ever Given",
    },
    {
        matchNumber: 333,
        agent0: "Sean^2",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 334,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "Sean^2",
        winner: "Sean^2",
    },
    {
        matchNumber: 335,
        agent0: "Team Drake",
        agent1: "Sean^2",
        winner: "Team Drake",
    },
    {
        matchNumber: 336,
        agent0: "Sean^2",
        agent1: "Team Drake",
        winner: "Sean^2",
    },
    {
        matchNumber: 337,
        agent0: "PDNN (= please do not nerf)",
        agent1: "Sean^2",
        winner: "PDNN (= please do not nerf)",
    },
    {
        matchNumber: 338,
        agent0: "PDNN (= please do not nerf)",
        agent1: "Sean^2",
        winner: "Sean^2",
    },
    {
        matchNumber: 339,
        agent0: ":3",
        agent1: "Sean^2",
        winner: ":3",
    },
    {
        matchNumber: 340,
        agent0: ":3",
        agent1: "Sean^2",
        winner: ":3",
    },
    {
        matchNumber: 341,
        agent0: "pyrados",
        agent1: "Sean^2",
        winner: "pyrados",
    },
    {
        matchNumber: 342,
        agent0: "pyrados",
        agent1: "Sean^2",
        winner: "Sean^2",
    },
    {
        matchNumber: 343,
        agent0: "Silver Snakes",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 344,
        agent0: "Silver Snakes",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 345,
        agent0: "Silver Snakes",
        agent1: "Mental Moles",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 346,
        agent0: "Silver Snakes",
        agent1: "Mental Moles",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 347,
        agent0: "Ascend",
        agent1: "Silver Snakes",
        winner: "Ascend",
    },
    {
        matchNumber: 348,
        agent0: "Ascend",
        agent1: "Silver Snakes",
        winner: null,
    },
    {
        matchNumber: 349,
        agent0: "blasterpoard",
        agent1: "Silver Snakes",
        winner: "blasterpoard",
    },
    {
        matchNumber: 350,
        agent0: "blasterpoard",
        agent1: "Silver Snakes",
        winner: null,
    },
    {
        matchNumber: 351,
        agent0: "ValGrowth",
        agent1: "Silver Snakes",
        winner: "ValGrowth",
    },
    {
        matchNumber: 352,
        agent0: "ValGrowth",
        agent1: "Silver Snakes",
        winner: "ValGrowth",
    },
    {
        matchNumber: 353,
        agent0: "Silver Snakes",
        agent1: "JABE",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 354,
        agent0: "JABE",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 355,
        agent0: "Silver Snakes",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 356,
        agent0: "Silver Snakes",
        agent1: "AvadaKedavra",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 357,
        agent0: "Silver Snakes",
        agent1: "import numpy as pd",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 358,
        agent0: "import numpy as pd",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 359,
        agent0: "Silver Snakes",
        agent1: "One Bot: 4 Ever Given",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 360,
        agent0: "One Bot: 4 Ever Given",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 361,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 362,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 363,
        agent0: "Silver Snakes",
        agent1: "Team Drake",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 364,
        agent0: "Team Drake",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 365,
        agent0: "PDNN (= please do not nerf)",
        agent1: "Silver Snakes",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 366,
        agent0: "Silver Snakes",
        agent1: "PDNN (= please do not nerf)",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 367,
        agent0: "Silver Snakes",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 368,
        agent0: "Silver Snakes",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 369,
        agent0: "Silver Snakes",
        agent1: "pyrados",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 370,
        agent0: "Silver Snakes",
        agent1: "pyrados",
        winner: "Silver Snakes",
    },
    {
        matchNumber: 371,
        agent0: "Mental Moles",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 372,
        agent0: "Mental Moles",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 373,
        agent0: "Ascend",
        agent1: "Die Jigglybluff",
        winner: "Ascend",
    },
    {
        matchNumber: 374,
        agent0: "Die Jigglybluff",
        agent1: "Ascend",
        winner: null,
    },
    {
        matchNumber: 375,
        agent0: "Die Jigglybluff",
        agent1: "blasterpoard",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 376,
        agent0: "blasterpoard",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 377,
        agent0: "Die Jigglybluff",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 378,
        agent0: "Die Jigglybluff",
        agent1: "ValGrowth",
        winner: null,
    },
    {
        matchNumber: 379,
        agent0: "JABE",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 380,
        agent0: "Die Jigglybluff",
        agent1: "JABE",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 381,
        agent0: "Die Jigglybluff",
        agent1: "AvadaKedavra",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 382,
        agent0: "Die Jigglybluff",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 383,
        agent0: "import numpy as pd",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 384,
        agent0: "Die Jigglybluff",
        agent1: "import numpy as pd",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 385,
        agent0: "One Bot: 4 Ever Given",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 386,
        agent0: "Die Jigglybluff",
        agent1: "One Bot: 4 Ever Given",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 387,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 388,
        agent0: "Die Jigglybluff",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 389,
        agent0: "Die Jigglybluff",
        agent1: "Team Drake",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 390,
        agent0: "Team Drake",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 391,
        agent0: "PDNN (= please do not nerf)",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 392,
        agent0: "Die Jigglybluff",
        agent1: "PDNN (= please do not nerf)",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 393,
        agent0: ":3",
        agent1: "Die Jigglybluff",
        winner: ":3",
    },
    {
        matchNumber: 394,
        agent0: ":3",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 395,
        agent0: "pyrados",
        agent1: "Die Jigglybluff",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 396,
        agent0: "Die Jigglybluff",
        agent1: "pyrados",
        winner: "Die Jigglybluff",
    },
    {
        matchNumber: 397,
        agent0: "Mental Moles",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 398,
        agent0: "Mental Moles",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 399,
        agent0: "blasterpoard",
        agent1: "Mental Moles",
        winner: "blasterpoard",
    },
    {
        matchNumber: 400,
        agent0: "Mental Moles",
        agent1: "blasterpoard",
        winner: "blasterpoard",
    },
    {
        matchNumber: 401,
        agent0: "ValGrowth",
        agent1: "Mental Moles",
        winner: "ValGrowth",
    },
    {
        matchNumber: 402,
        agent0: "ValGrowth",
        agent1: "Mental Moles",
        winner: "ValGrowth",
    },
    {
        matchNumber: 403,
        agent0: "Mental Moles",
        agent1: "JABE",
        winner: "JABE",
    },
    {
        matchNumber: 404,
        agent0: "Mental Moles",
        agent1: "JABE",
        winner: "JABE",
    },
    {
        matchNumber: 405,
        agent0: "AvadaKedavra",
        agent1: "Mental Moles",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 406,
        agent0: "Mental Moles",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 407,
        agent0: "Mental Moles",
        agent1: "import numpy as pd",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 408,
        agent0: "import numpy as pd",
        agent1: "Mental Moles",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 409,
        agent0: "Mental Moles",
        agent1: "One Bot: 4 Ever Given",
        winner: "Mental Moles",
    },
    {
        matchNumber: 410,
        agent0: "Mental Moles",
        agent1: "One Bot: 4 Ever Given",
        winner: "Mental Moles",
    },
    {
        matchNumber: 411,
        agent0: "Mental Moles",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 412,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "Mental Moles",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 413,
        agent0: "Team Drake",
        agent1: "Mental Moles",
        winner: "Team Drake",
    },
    {
        matchNumber: 414,
        agent0: "Mental Moles",
        agent1: "Team Drake",
        winner: "Mental Moles",
    },
    {
        matchNumber: 415,
        agent0: "Mental Moles",
        agent1: "PDNN (= please do not nerf)",
        winner: "PDNN (= please do not nerf)",
    },
    {
        matchNumber: 416,
        agent0: "Mental Moles",
        agent1: "PDNN (= please do not nerf)",
        winner: "Mental Moles",
    },
    {
        matchNumber: 417,
        agent0: "Mental Moles",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 418,
        agent0: ":3",
        agent1: "Mental Moles",
        winner: ":3",
    },
    {
        matchNumber: 419,
        agent0: "Mental Moles",
        agent1: "pyrados",
        winner: "Mental Moles",
    },
    {
        matchNumber: 420,
        agent0: "Mental Moles",
        agent1: "pyrados",
        winner: "pyrados",
    },
    {
        matchNumber: 421,
        agent0: "blasterpoard",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 422,
        agent0: "Ascend",
        agent1: "blasterpoard",
        winner: "Ascend",
    },
    {
        matchNumber: 423,
        agent0: "ValGrowth",
        agent1: "Ascend",
        winner: null,
    },
    {
        matchNumber: 424,
        agent0: "Ascend",
        agent1: "ValGrowth",
        winner: null,
    },
    {
        matchNumber: 425,
        agent0: "JABE",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 426,
        agent0: "JABE",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 427,
        agent0: "AvadaKedavra",
        agent1: "Ascend",
        winner: null,
    },
    {
        matchNumber: 428,
        agent0: "Ascend",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 429,
        agent0: "import numpy as pd",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 430,
        agent0: "import numpy as pd",
        agent1: "Ascend",
        winner: null,
    },
    {
        matchNumber: 431,
        agent0: "One Bot: 4 Ever Given",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 432,
        agent0: "Ascend",
        agent1: "One Bot: 4 Ever Given",
        winner: "Ascend",
    },
    {
        matchNumber: 433,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 434,
        agent0: "Ascend",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Ascend",
    },
    {
        matchNumber: 435,
        agent0: "Team Drake",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 436,
        agent0: "Ascend",
        agent1: "Team Drake",
        winner: "Ascend",
    },
    {
        matchNumber: 437,
        agent0: "PDNN (= please do not nerf)",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 438,
        agent0: "PDNN (= please do not nerf)",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 439,
        agent0: ":3",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 440,
        agent0: "Ascend",
        agent1: ":3",
        winner: "Ascend",
    },
    {
        matchNumber: 441,
        agent0: "pyrados",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 442,
        agent0: "pyrados",
        agent1: "Ascend",
        winner: "Ascend",
    },
    {
        matchNumber: 443,
        agent0: "ValGrowth",
        agent1: "blasterpoard",
        winner: null,
    },
    {
        matchNumber: 444,
        agent0: "ValGrowth",
        agent1: "blasterpoard",
        winner: "ValGrowth",
    },
    {
        matchNumber: 445,
        agent0: "JABE",
        agent1: "blasterpoard",
        winner: "JABE",
    },
    {
        matchNumber: 446,
        agent0: "blasterpoard",
        agent1: "JABE",
        winner: "blasterpoard",
    },
    {
        matchNumber: 447,
        agent0: "blasterpoard",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 448,
        agent0: "AvadaKedavra",
        agent1: "blasterpoard",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 449,
        agent0: "blasterpoard",
        agent1: "import numpy as pd",
        winner: "blasterpoard",
    },
    {
        matchNumber: 450,
        agent0: "import numpy as pd",
        agent1: "blasterpoard",
        winner: "blasterpoard",
    },
    {
        matchNumber: 451,
        agent0: "One Bot: 4 Ever Given",
        agent1: "blasterpoard",
        winner: "blasterpoard",
    },
    {
        matchNumber: 452,
        agent0: "blasterpoard",
        agent1: "One Bot: 4 Ever Given",
        winner: "blasterpoard",
    },
    {
        matchNumber: 453,
        agent0: "blasterpoard",
        agent1: "Don't Hug Me I'm Scared",
        winner: "blasterpoard",
    },
    {
        matchNumber: 454,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "blasterpoard",
        winner: "blasterpoard",
    },
    {
        matchNumber: 455,
        agent0: "blasterpoard",
        agent1: "Team Drake",
        winner: "blasterpoard",
    },
    {
        matchNumber: 456,
        agent0: "Team Drake",
        agent1: "blasterpoard",
        winner: "blasterpoard",
    },
    {
        matchNumber: 457,
        agent0: "blasterpoard",
        agent1: "PDNN (= please do not nerf)",
        winner: "blasterpoard",
    },
    {
        matchNumber: 458,
        agent0: "PDNN (= please do not nerf)",
        agent1: "blasterpoard",
        winner: "blasterpoard",
    },
    {
        matchNumber: 459,
        agent0: ":3",
        agent1: "blasterpoard",
        winner: ":3",
    },
    {
        matchNumber: 460,
        agent0: ":3",
        agent1: "blasterpoard",
        winner: ":3",
    },
    {
        matchNumber: 461,
        agent0: "blasterpoard",
        agent1: "pyrados",
        winner: "blasterpoard",
    },
    {
        matchNumber: 462,
        agent0: "pyrados",
        agent1: "blasterpoard",
        winner: "pyrados",
    },
    {
        matchNumber: 463,
        agent0: "JABE",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 464,
        agent0: "ValGrowth",
        agent1: "JABE",
        winner: "ValGrowth",
    },
    {
        matchNumber: 465,
        agent0: "ValGrowth",
        agent1: "AvadaKedavra",
        winner: "ValGrowth",
    },
    {
        matchNumber: 466,
        agent0: "ValGrowth",
        agent1: "AvadaKedavra",
        winner: "ValGrowth",
    },
    {
        matchNumber: 467,
        agent0: "import numpy as pd",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 468,
        agent0: "import numpy as pd",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 469,
        agent0: "ValGrowth",
        agent1: "One Bot: 4 Ever Given",
        winner: "ValGrowth",
    },
    {
        matchNumber: 470,
        agent0: "ValGrowth",
        agent1: "One Bot: 4 Ever Given",
        winner: "ValGrowth",
    },
    {
        matchNumber: 471,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 472,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 473,
        agent0: "Team Drake",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 474,
        agent0: "Team Drake",
        agent1: "ValGrowth",
        winner: "ValGrowth",
    },
    {
        matchNumber: 475,
        agent0: "ValGrowth",
        agent1: "PDNN (= please do not nerf)",
        winner: "ValGrowth",
    },
    {
        matchNumber: 476,
        agent0: "ValGrowth",
        agent1: "PDNN (= please do not nerf)",
        winner: "ValGrowth",
    },
    {
        matchNumber: 477,
        agent0: "ValGrowth",
        agent1: ":3",
        winner: "ValGrowth",
    },
    {
        matchNumber: 478,
        agent0: "ValGrowth",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 479,
        agent0: "ValGrowth",
        agent1: "pyrados",
        winner: "ValGrowth",
    },
    {
        matchNumber: 480,
        agent0: "ValGrowth",
        agent1: "pyrados",
        winner: "ValGrowth",
    },
    {
        matchNumber: 481,
        agent0: "AvadaKedavra",
        agent1: "JABE",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 482,
        agent0: "AvadaKedavra",
        agent1: "JABE",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 483,
        agent0: "JABE",
        agent1: "import numpy as pd",
        winner: null,
    },
    {
        matchNumber: 484,
        agent0: "JABE",
        agent1: "import numpy as pd",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 485,
        agent0: "JABE",
        agent1: "One Bot: 4 Ever Given",
        winner: "JABE",
    },
    {
        matchNumber: 486,
        agent0: "One Bot: 4 Ever Given",
        agent1: "JABE",
        winner: "JABE",
    },
    {
        matchNumber: 487,
        agent0: "JABE",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 488,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "JABE",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 489,
        agent0: "Team Drake",
        agent1: "JABE",
        winner: "JABE",
    },
    {
        matchNumber: 490,
        agent0: "Team Drake",
        agent1: "JABE",
        winner: "JABE",
    },
    {
        matchNumber: 491,
        agent0: "PDNN (= please do not nerf)",
        agent1: "JABE",
        winner: "JABE",
    },
    {
        matchNumber: 492,
        agent0: "JABE",
        agent1: "PDNN (= please do not nerf)",
        winner: "JABE",
    },
    {
        matchNumber: 493,
        agent0: "JABE",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 494,
        agent0: ":3",
        agent1: "JABE",
        winner: ":3",
    },
    {
        matchNumber: 495,
        agent0: "pyrados",
        agent1: "JABE",
        winner: "JABE",
    },
    {
        matchNumber: 496,
        agent0: "pyrados",
        agent1: "JABE",
        winner: "JABE",
    },
    {
        matchNumber: 497,
        agent0: "import numpy as pd",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 498,
        agent0: "import numpy as pd",
        agent1: "AvadaKedavra",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 499,
        agent0: "One Bot: 4 Ever Given",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 500,
        agent0: "AvadaKedavra",
        agent1: "One Bot: 4 Ever Given",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 501,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 502,
        agent0: "AvadaKedavra",
        agent1: "Don't Hug Me I'm Scared",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 503,
        agent0: "AvadaKedavra",
        agent1: "Team Drake",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 504,
        agent0: "Team Drake",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 505,
        agent0: "AvadaKedavra",
        agent1: "PDNN (= please do not nerf)",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 506,
        agent0: "AvadaKedavra",
        agent1: "PDNN (= please do not nerf)",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 507,
        agent0: ":3",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 508,
        agent0: "AvadaKedavra",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 509,
        agent0: "pyrados",
        agent1: "AvadaKedavra",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 510,
        agent0: "AvadaKedavra",
        agent1: "pyrados",
        winner: "AvadaKedavra",
    },
    {
        matchNumber: 511,
        agent0: "One Bot: 4 Ever Given",
        agent1: "import numpy as pd",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 512,
        agent0: "One Bot: 4 Ever Given",
        agent1: "import numpy as pd",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 513,
        agent0: "import numpy as pd",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 514,
        agent0: "import numpy as pd",
        agent1: "Don't Hug Me I'm Scared",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 515,
        agent0: "Team Drake",
        agent1: "import numpy as pd",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 516,
        agent0: "Team Drake",
        agent1: "import numpy as pd",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 517,
        agent0: "import numpy as pd",
        agent1: "PDNN (= please do not nerf)",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 518,
        agent0: "import numpy as pd",
        agent1: "PDNN (= please do not nerf)",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 519,
        agent0: ":3",
        agent1: "import numpy as pd",
        winner: ":3",
    },
    {
        matchNumber: 520,
        agent0: "import numpy as pd",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 521,
        agent0: "pyrados",
        agent1: "import numpy as pd",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 522,
        agent0: "pyrados",
        agent1: "import numpy as pd",
        winner: "import numpy as pd",
    },
    {
        matchNumber: 523,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "One Bot: 4 Ever Given",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 524,
        agent0: "One Bot: 4 Ever Given",
        agent1: "Don't Hug Me I'm Scared",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 525,
        agent0: "One Bot: 4 Ever Given",
        agent1: "Team Drake",
        winner: "One Bot: 4 Ever Given",
    },
    {
        matchNumber: 526,
        agent0: "Team Drake",
        agent1: "One Bot: 4 Ever Given",
        winner: "Team Drake",
    },
    {
        matchNumber: 527,
        agent0: "PDNN (= please do not nerf)",
        agent1: "One Bot: 4 Ever Given",
        winner: "PDNN (= please do not nerf)",
    },
    {
        matchNumber: 528,
        agent0: "PDNN (= please do not nerf)",
        agent1: "One Bot: 4 Ever Given",
        winner: "PDNN (= please do not nerf)",
    },
    {
        matchNumber: 529,
        agent0: "One Bot: 4 Ever Given",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 530,
        agent0: ":3",
        agent1: "One Bot: 4 Ever Given",
        winner: ":3",
    },
    {
        matchNumber: 531,
        agent0: "One Bot: 4 Ever Given",
        agent1: "pyrados",
        winner: "pyrados",
    },
    {
        matchNumber: 532,
        agent0: "One Bot: 4 Ever Given",
        agent1: "pyrados",
        winner: "pyrados",
    },
    {
        matchNumber: 533,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "Team Drake",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 534,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "Team Drake",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 535,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "PDNN (= please do not nerf)",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 536,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "PDNN (= please do not nerf)",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 537,
        agent0: "Don't Hug Me I'm Scared",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 538,
        agent0: ":3",
        agent1: "Don't Hug Me I'm Scared",
        winner: ":3",
    },
    {
        matchNumber: 539,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "pyrados",
        winner: "pyrados",
    },
    {
        matchNumber: 540,
        agent0: "Don't Hug Me I'm Scared",
        agent1: "pyrados",
        winner: "Don't Hug Me I'm Scared",
    },
    {
        matchNumber: 541,
        agent0: "PDNN (= please do not nerf)",
        agent1: "Team Drake",
        winner: "PDNN (= please do not nerf)",
    },
    {
        matchNumber: 542,
        agent0: "Team Drake",
        agent1: "PDNN (= please do not nerf)",
        winner: "Team Drake",
    },
    {
        matchNumber: 543,
        agent0: "Team Drake",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 544,
        agent0: ":3",
        agent1: "Team Drake",
        winner: ":3",
    },
    {
        matchNumber: 545,
        agent0: "Team Drake",
        agent1: "pyrados",
        winner: "pyrados",
    },
    {
        matchNumber: 546,
        agent0: "Team Drake",
        agent1: "pyrados",
        winner: "pyrados",
    },
    {
        matchNumber: 547,
        agent0: "PDNN (= please do not nerf)",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 548,
        agent0: "PDNN (= please do not nerf)",
        agent1: ":3",
        winner: ":3",
    },
    {
        matchNumber: 549,
        agent0: "PDNN (= please do not nerf)",
        agent1: "pyrados",
        winner: null,
    },
    {
        matchNumber: 550,
        agent0: "PDNN (= please do not nerf)",
        agent1: "pyrados",
        winner: "pyrados",
    },
    {
        matchNumber: 551,
        agent0: ":3",
        agent1: "pyrados",
        winner: ":3",
    },
    {
        matchNumber: 552,
        agent0: "pyrados",
        agent1: ":3",
        winner: ":3",
    },
];
