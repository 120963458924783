import * as React from "react";
import { Data, ResultRow } from "./QualificationRankings.styles";
import { IRanking, qualificationRankings } from "./qualificationRankings.data";
import { List } from "@fluentui/react";
import { useCallback } from "react";

export const QualificationRankings: React.FC<React.PropsWithChildren<unknown>> = () => {
    const onRenderCell = useCallback((item?: IRanking, index?: number | undefined) => {
        if (item === undefined) {
            return null;
        }
        const { rank, teamName, wins, losses, ties } = item;
        const isAlternateRow = (index ?? 0) % 2 === 0;
        return (
            <ResultRow isAlternateRow={isAlternateRow}>
                {rank}
                <Data status="T">{teamName}</Data>
                <Data status="W">{wins}</Data>
                <Data status="L">{losses}</Data>
                <Data status="T">{ties}</Data>
            </ResultRow>
        );
    }, []);
    return (
        <React.Fragment>
            <List items={qualificationRankings} onRenderCell={onRenderCell} />
        </React.Fragment>
    );
};
