import * as React from "react";
import { Pivot, PivotItem } from "@fluentui/react";
import { QualificationRankings } from "./QualificationGames/QualificationRankings";
import { QualificationScores } from "./QualificationGames/QualificationGames";
import { QualificationRankingsHeader } from "./QualificationGames/QualificationRankingsHeader";
import { Root } from "./History.styles";
import { useTranslation } from "react-i18next";
import { AuthenticatedFrame } from "../../../AuthenticatedFrame/AuthenticatedFrame";

export const HistoryContent: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();

    return (
        <AuthenticatedFrame title={t("qualifiers")}>
            <Root>
                <Pivot>
                    <PivotItem headerText={t("results")}>
                        <QualificationScores />
                    </PivotItem>
                    <PivotItem headerText={t("rankings")}>
                        <QualificationRankingsHeader />
                        <QualificationRankings />
                    </PivotItem>
                </Pivot>
            </Root>
        </AuthenticatedFrame>
    );
};
