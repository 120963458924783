import * as React from "react";
import { Agent, AgentsRoot, ResultRow } from "../History.styles";
import { IScore, qualificationScores } from "./qualficationScores.data";
import { List, Text, TextField } from "@fluentui/react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const getStatus = (winner: string | null, agent: string): "T" | "W" | "L" => {
    if (winner === null) {
        return "T";
    } else {
        return agent === winner ? "W" : "L";
    }
};

export const QualificationScores: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const [query, setQuery] = useState("");
    const onQuery = useCallback(
        (_: unknown, newValue: string | undefined) => {
            setQuery(newValue ?? "");
        },
        [setQuery]
    );

    const filteredScores = useMemo(() => {
        const lowerCaseQuery = query.toLowerCase();
        return qualificationScores.filter((score) => {
            return score.agent0.toLowerCase().startsWith(lowerCaseQuery) || score.agent1.toLowerCase().startsWith(lowerCaseQuery);
        });
    }, [query]);

    const onRenderCell = useCallback((item?: IScore, index?: number | undefined) => {
        if (item === undefined) {
            return null;
        }
        const { matchNumber, agent0, agent1, winner } = item;
        const agent0Status = getStatus(winner, agent0);
        const agent1Status = getStatus(winner, agent1);
        const isAlternateRow = (index ?? 0) % 2 === 0;
        return (
            <ResultRow isAlternateRow={isAlternateRow}>
                <Text>#{matchNumber}</Text>
                <AgentsRoot>
                    <Agent status={agent0Status}>
                        <Text>
                            [0] {agent0} {agent0Status}
                        </Text>
                    </Agent>
                    <Agent status={agent1Status}>
                        <Text>
                            [1] {agent1} {agent1Status}
                        </Text>
                    </Agent>
                </AgentsRoot>
            </ResultRow>
        );
    }, []);
    return (
        <React.Fragment>
            <TextField label={t("filterTeam")} onChange={onQuery} />
            <List items={filteredScores} onRenderCell={onRenderCell} />
        </React.Fragment>
    );
};
