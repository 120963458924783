import styled from "styled-components";
import { Palette } from "../../../../../theme/Palette";

export const AgentsRoot = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
`;

interface IStatusProps {
    readonly status: "W" | "T" | "L";
}

const getColour = (status: "W" | "T" | "L") => {
    if (status === "W") {
        return "#32a852";
    } else if (status === "T") {
        return "#424242";
    } else {
        return "#ff0000";
    }
};

export const Data = styled("div")<IStatusProps>`
    box-sizing: border-box;
    color: ${({ status }) => getColour(status)};
`;

interface IRowProps {
    readonly isAlternateRow: boolean;
}

export const ResultRow = styled("div")<IRowProps>`
    background: ${({ isAlternateRow }) => (isAlternateRow ? Palette.Neutral0 : Palette.Neutral20)};
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 16px;
    text-align: center;
`;

export const HeaderRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 16px;
    text-align: center;
`;

export const HeaderText = styled.span`
    font-weight: 700;
    color: ${Palette.Neutral90};
`;
