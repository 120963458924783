import styled from "styled-components";
import { Palette } from "../../../../theme/Palette";

export const Root = styled.div`
    background: ${Palette.Neutral0};
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
`;

export const AgentsRoot = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
`;

interface IAgentProps {
    readonly status: "W" | "T" | "L";
}

const getColour = (status: "W" | "T" | "L") => {
    if (status === "W") {
        return "#32a852";
    } else if (status === "T") {
        return "#424242";
    } else {
        return "#ff0000";
    }
};

export const Agent = styled("div")<IAgentProps>`
    padding: 16px;
    box-sizing: border-box;
    color: ${({ status }) => getColour(status)};
`;

interface IRowProps {
    readonly isAlternateRow: boolean;
}

export const ResultRow = styled("div")<IRowProps>`
    background: ${({ isAlternateRow }) => (isAlternateRow ? Palette.Neutral0 : Palette.Neutral20)};
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
