import * as React from "react";
import { useTranslation } from "react-i18next";
import { WithAuth } from "../../../Auth/WithAuth";
import { SEO } from "../../../SEO/SEO";
import { HistoryContent } from "./HistoryContent";

const History: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    const title = t("history");
    const description = t("historicalGames");
    return (
        <WithAuth>
            <SEO description={description} title={title} />
            <HistoryContent />
        </WithAuth>
    );
};

export default History;
