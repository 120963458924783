export interface IRanking {
    readonly rank: number;
    readonly teamName: string;
    readonly wins: number;
    readonly losses: number;
    readonly ties: number;
    readonly score: number;
}

export const qualificationRankings = [
    {
        rank: 1,
        teamName: "Wigglyblob",
        wins: 41,
        losses: 2,
        ties: 3,
        score: 46,
    },
    {
        rank: 2,
        teamName: "ValGrowth",
        wins: 39,
        losses: 2,
        ties: 5,
        score: 46,
    },
    {
        rank: 3,
        teamName: ":3",
        wins: 39,
        losses: 7,
        ties: 0,
        score: 46,
    },
    {
        rank: 4,
        teamName: "bruh",
        wins: 33,
        losses: 10,
        ties: 3,
        score: 46,
    },
    {
        rank: 5,
        teamName: "Die Jigglybluff",
        wins: 33,
        losses: 10,
        ties: 3,
        score: 46,
    },
    {
        rank: 6,
        teamName: "AvadaKedavra",
        wins: 33,
        losses: 11,
        ties: 2,
        score: 46,
    },
    {
        rank: 7,
        teamName: "Ascend",
        wins: 32,
        losses: 2,
        ties: 12,
        score: 46,
    },
    {
        rank: 8,
        teamName: "Silver Snakes",
        wins: 32,
        losses: 12,
        ties: 2,
        score: 46,
    },
    {
        rank: 9,
        teamName: "Refiner",
        wins: 27,
        losses: 17,
        ties: 2,
        score: 46,
    },
    {
        rank: 10,
        teamName: "The Screw Shop",
        wins: 24,
        losses: 22,
        ties: 0,
        score: 46,
    },
    {
        rank: 11,
        teamName: "blasterpoard",
        wins: 23,
        losses: 19,
        ties: 4,
        score: 46,
    },
    {
        rank: 12,
        teamName: "rng for the win :>",
        wins: 23,
        losses: 23,
        ties: 0,
        score: 46,
    },
    {
        rank: 13,
        teamName: "Totoro",
        wins: 22,
        losses: 23,
        ties: 1,
        score: 46,
    },
    {
        rank: 14,
        teamName: "LEVAI ACKERMAN",
        wins: 22,
        losses: 23,
        ties: 1,
        score: 46,
    },
    {
        rank: 15,
        teamName: "🤔",
        wins: 20,
        losses: 26,
        ties: 0,
        score: 46,
    },
    {
        rank: 16,
        teamName: "import numpy as pd",
        wins: 19,
        losses: 25,
        ties: 2,
        score: 46,
    },
    {
        rank: 17,
        teamName: "Don't Hug Me I'm Scared",
        wins: 17,
        losses: 29,
        ties: 0,
        score: 46,
    },
    {
        rank: 18,
        teamName: "JABE",
        wins: 14,
        losses: 31,
        ties: 1,
        score: 46,
    },
    {
        rank: 19,
        teamName: "Sean^2",
        wins: 12,
        losses: 34,
        ties: 0,
        score: 46,
    },
    {
        rank: 20,
        teamName: "pyrados",
        wins: 9,
        losses: 36,
        ties: 1,
        score: 46,
    },
    {
        rank: 21,
        teamName: "Mental Moles",
        wins: 6,
        losses: 40,
        ties: 0,
        score: 46,
    },
    {
        rank: 22,
        teamName: "PDNN (= please do not nerf)",
        wins: 5,
        losses: 40,
        ties: 1,
        score: 46,
    },
    {
        rank: 23,
        teamName: "Team Drake",
        wins: 4,
        losses: 41,
        ties: 1,
        score: 46,
    },
    {
        rank: 24,
        teamName: "One Bot: 4 Ever Given",
        wins: 2,
        losses: 44,
        ties: 0,
        score: 46,
    },
];
